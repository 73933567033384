.form {
  width: 60%;
  /* height: 400px; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  background-color: black;
  padding: 30px;
  color: white;
}

.btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.heading {
  /* margin-left: 50px; */
  margin-bottom: 40px;
  text-align: center;
}
